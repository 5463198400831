import { type Component } from "solid-js";
import { Collapsible } from "~/components/ui";

export const ProductDescription: Component<{ description: string }> = (
  props
) => {
  return (
    <Collapsible
      name="description"
      trigger="Description"
      triggerClass="font-bold"
      defaultOpen
    >
      <p class="text-sm text-roma-dark-grey leading-7 my-4">
        {props.description}
      </p>
    </Collapsible>
  );
};
